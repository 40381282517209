'use strict';

/**
 * Display badge based on product variant response
 * @param {Object} badgeData from product data
 * @return {string} HTML Output
 */
function displayBadge(badgeData) {
    let result = '';

    if (!(badgeData && badgeData.gridBadge)) {
        return result;
    }

    const gridBadgeValue = badgeData.gridBadge.value || null;
    const gridBadgeDisplay = badgeData.gridBadge.display || null;
    const hasSaleSwatchSelected = $('.js-color-swatch.js-onsale.selected').length;
    const hideDynamicBadges = !!badgeData.gridBadge.hideDynamicBadges;
    // In contrast with the grid tile, the PDP 'Sale' badge will be omitted from this static type array and handled dynamically
    const staticTypes = ['BestSeller', 'New', 'Coming Soon'];
    const isStaticType = staticTypes.includes(badgeData.gridBadge.value);

    let badgeType;

    if (badgeData.svg) {
        result = `
            <div class="product-badge product-badge--pdp product-badge--icon">
                <svg class="svgicon"><use href="${'#' + badgeData.svg}"></use></svg>
            </div>
        `;
    } else if (gridBadgeValue && gridBadgeDisplay && gridBadgeValue.toLowerCase() !== 'sale') {
        badgeType = gridBadgeValue.toLowerCase();
    } else if (!hideDynamicBadges && hasSaleSwatchSelected && !isStaticType) {
        badgeType = 'sale';
    }

    if (badgeType) {
        result = `
            <div class="product-badge product-badge--pdp product-badge--${badgeType}">
                ${gridBadgeDisplay}
            </div>`;
    }

    return result;
}

/**
 * Display PDP image gallery tech badge based on product technology
 * @param {Object} badgeData from pdp data
 * @return {string} HTML Output
 */
function displayTechBadge(badgeData) {
    let result = '';

    if (!badgeData) {
        return result;
    }

    result = `
        <div class="product-tech-badge ${badgeData.svgClass}">
            <svg viewbox="0 0 ${badgeData.svgWidth} ${badgeData.svgHeight}">
                <use xlink:href="${'#' + badgeData.svgID}"></use>
            </svg>
        </div>`;

    return result;
}

module.exports = {
    displayBadge,
    displayTechBadge
};
