const utils = require('../../components/utils');
const debounce = require('lodash/debounce');
const breakpoints = require('../../components/breakpoints');

/**
 * Detects when an element loses focus and fires a callback function
 * @param {*} callback function to be called after losing focus
 * @param {string} selector set selector(s) to filter the descendants of $(docuemnt) that trigger the event
 * @return {jQuery} the jQuery element passed through
 */
$.fn.loseFocus = function (callback, selector = '*') {
    const $self = this;
    $(document).on('focus.loseFocus click.loseFocus', selector, function (e) {
        if (!$.contains($self[0], e.target)) {
            // focus has been lost, remove event listener
            $(document).off('.loseFocus');
            // fire supplied callback function
            callback();
        }
    });
    return this;
};

/**
 * Remove any invalid characters, as defined in the regular expression, upon leaving the input field.
 * @param {regex} invalidsRegex Regular Expression or regex string. If omitted, will use the string in the data-invalids attribute.
 * @param {string} replacement A string to replace the removed characters. Defaults to empty string.
 * @returns {jQuery} jQuery collection that was passed in, with focusout event listeners attached to each field in the collection.
 * @example
 *     <input type="text" id="noVowels" data-invalids="aeiou" />
 *     $('#noVowels').stripInvalidChars(); // invalid chars are found in data attribute and converted into a regex
 * @example
 *     $('.digitsOnly').stripInvalidChars(/[^\d]/g); // regex is passed as an arg
 */
$.fn.stripInvalidChars = function (invalidsRegex, replacement = '') {
    this.each(function () {
        let $field = $(this);

        if (!invalidsRegex) {
            // look for a string of invalid characters in a data attribute
            let invalidsString = $field.data('invalids');
            if (invalidsString) {
                invalidsRegex = new RegExp(`[${invalidsString}]`, 'g'); // eslint-disable-line no-param-reassign
            }
        }
        if (!invalidsRegex) return;

        $field.on('focusout.stripInvalids', function () { // focusout will trigger before blur, so the field will get updated before validated by clientSideValidation().
            let val = $field.val();
            val = val.replace(invalidsRegex, replacement);
            $field.val(val);
        });
    });
    return this;
};

/**
 * Tests if an element is viewable within the viewport.
 * @example
 *     if ($('#placeholder').isInViewPort(true, true)) {
 *         // at least a part of this thing is within the viewport, but only tested vertically
 *     }
 * @param {boolean} partial set to true to test if any part of the element is within the viewport. Otherwise tests if the entire element is visible within the viewport.
 * @param {boolean} onlyTestVertically set to true to only test vertically. If omitted, it will test both vertically and horizontally.
 * @return {boolean} true if any part of the element is in the viewport. false if the element is off the screen or not displayed.
 */
$.fn.isInViewport = function (partial, onlyTestVertically) {
    if (!$(this).length) {
        return false;
    }

    var offset = $(this).offset();
    var element = {
        top: offset.top,
        bottom: offset.top + $(this).outerHeight(),
        left: offset.left,
        right: offset.left + $(this).outerWidth()
    };

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    var viewportLeft = $(window).scrollLeft();
    var viewportRight = viewportLeft + $(window).width();

    if (onlyTestVertically) {
        if (partial) {
            return element.bottom > viewportTop && element.top < viewportBottom;
        }
        return element.top >= viewportTop && element.bottom <= viewportBottom;
    }

    if (partial) {
        return (element.bottom > viewportTop && element.top < viewportBottom) && (element.right > viewportLeft && element.left < viewportRight);
    }
    return (element.top >= viewportTop && element.bottom <= viewportBottom) && (element.left >= viewportLeft && element.right <= viewportRight);
};

/**
 * Scrolls the document to the selected element.
 * @example
 *      // scroll the #next_section to the top of the page, accounting for the height of the header, over 750ms.
 *      var offset = $('.siteheader').outerHeight() + 10;
 *      $('#next_section').scrollTo(offset, 750);
 * @param {number} offset The number of pixels down from the top of the screen to offset the selected element.
 * @param {number} duration The duration of the scroll animation in miliseconds.
 * @return {jQuery} The jQuery collection passed through.
 */
$.fn.scrollTo = function (offset, duration) {
    var settings = {
        offset: offset || 0,
        duration: duration || 1000
    };

    return this.each(function () {
        var position = $(this).offset();
        $('body, html').stop().animate({
            scrollTop: position.top - settings.offset
        }, settings.duration);
        return this;
    });
};

/**
 *
 * @param {Date} desiredTimestamp A UTC timestamp (in ms), this timestamp represents the UTC time in which a callback function should be executed
 * @param {*} callback The callback function to be executed
 */
$.fireCallbackAfterTimePassed = function (desiredTimestamp, callback) {
    let currentTimestamp = new Date().getTime();
    let timeout = (desiredTimestamp - currentTimestamp) + 1000; // calculate the time in ms which the setTimeout function should be called add a 1 second buffer
    const SETTIMEOUT_MAX = Math.pow(2, 31) - 1; // max 32 bit integer value

    if (currentTimestamp <= desiredTimestamp && timeout < SETTIMEOUT_MAX) {
        setTimeout(callback, timeout);
    }
};

/**
 * Extends jQuery .slideUp and .slideDown functions to remove d-none before trying to slideDown.
 * @param {string} display Whether to 'show' or 'hide' the content. Undefined will toggle the display.
 * @param {(string|number)} duration A string or number determining how long the animation will run.
 * @return {jQuery} The jQuery collection passed through.
 */
$.fn.slide = function (display, duration) {
    return this.each(function () {
        var $el = $(this);
        display = display || ($el.is(':visible') ? 'hide' : 'show'); // eslint-disable-line no-param-reassign
        if (display === 'show') {
            if ($el.hasClass('d-none')) {
                $el.removeClass('d-none').hide().stop().slideDown(duration);
            } else {
                $el.stop().slideDown(duration);
            }
        } else if (display === 'hide') {
            $el.stop().slideUp(duration);
        }
        return this;
    });
};

/**
 * jQuery Plugin to launch a Bootstrap modal with the given content.
 * Example 1:
 *      $.modal('Thanks for signing up!');
 * Example 2:
 *      $.modal({
 *          title: 'Warning!',
 *          content: 'This computer will self-destruct in 5...',
 *          modalSizeClass: 'modal-sm'
 *      });
 * Bootstrap Modal documentation: https://getbootstrap.com/docs/4.0/components/modal/
 * Also see createModalMarkup() in utils.js
 *
 * @param {string/object} options The content to put in the modal or an object with content and options.
 * @returns {jquery} The created modal jQuery object.
 */
$.modal = function (options) {
    var content = '';
    if (typeof options === 'string') {
        content = options;
        options = {}; // eslint-disable-line no-param-reassign
    } else if (typeof options === 'object') {
        content = options.content || '';
        delete options.content; // eslint-disable-line no-param-reassign
    }

    var defaults = {
        title: '',
        modalSizeClass: 'modal-sm'
    };

    var settings = $.extend({}, defaults, options);

    var html = utils.createModalMarkup(content, settings);

    var $modal = $(html).modal();

    if (options.destroyOnClose) {
        $modal.one('hidden.bs.modal', function () {
            $modal.remove();
        });
    }

    if (options.dataAttrs) {
        Object.keys(options.dataAttrs).forEach((dataAttrKey) => {
            $modal.data(dataAttrKey, options.dataAttrs[dataAttrKey]);
        });
    }

    return $modal;
};

/**
 * Enable and Disable Scroll Plugins
 */
$(function () {
    let $html = $('html');
    let $globalBanner = $('.globalbanner');

    /**
     * Removes .no-scroll class and unsets padding offset
     */
    $.enableScroll = function () {
        // only enable scrolling if menu is not active
        if (!$html.hasClass('menu-active')) {
            $html.removeClass('no-scroll').css('padding-right', '');
            $globalBanner.css({
                right: '',
                'padding-right': ''
            });
        }
    };

    /**
     * Adds .no-scroll class and offsets scrollbar
     */
    $.disableScroll = function () {
        // executing $.disableScroll() multiple times without
        // running $.enableScroll() will cause the page to shift
        if ($html.hasClass('no-scroll')) return;
        let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        $html.addClass('no-scroll').css('padding-right', scrollbarWidth);
        $globalBanner.css({
            right: scrollbarWidth,
            'padding-right': scrollbarWidth
        });
    };
});

// prevent the page from scrolling whenever ANY modal is shown.
$(window).on('show.bs.modal, show.drawer', function () {
    $.disableScroll();
}).on('hidden.bs.modal, hidden.drawer', function () {
    if (!$('.drawer.show').length && !$('.modal.show').length) { // only enable scroll if no other modal or drawers are active on page
        $.enableScroll();
    }
});

// trigger an event when the window changes breakpoint sizes
const $breakpoints = $('.bootstrap-breakpoints div'); // defined in /components/bootstrap/breakpoints.isml
let previousBp;

/**
 * resizeHandler is called after the window has finished being resized and triggers
 * the "breakpoint:changed" event only when we resize from one breakpoint size into another.
 * The event passes the previous and new breakpoint name.
 * @example usage:
 *      $(window).on('breakpoint:changed', function (e, data) {
 *          console.log(`We resized from ${data.previousBp} to ${data.currentBp}.`);
 *          console.log(`${data.currentBp} is a ${
 *              ['lg', 'xl'].includes(data.currentBp) ? 'desktop' : 'mobile'
 *          } breakpoint.`);
 *      });
 *      // example output:
 *      // > 'We resized from md to lg.'
 *      // > 'lg is a desktop breakpoint.'
 */
function resizeHandler() {
    if (!$breakpoints.length) {
        // the breakpoints element is missing.
        $(window).off('resize.breakpointEvent');
        return;
    }

    const currentBp = breakpoints.getCurrentBreakpoint();

    if (currentBp !== previousBp) {
        $(document).trigger('breakpoint:changed', {
            previousBp,
            currentBp
        });
        previousBp = currentBp;
    }
    // else the window resized, but we did not change breakpoints.
}

resizeHandler(); // call resizeHandler on page load.

$(window).on('resize.breakpointEvent', debounce(resizeHandler));

