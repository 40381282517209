'use strict';

var passwordChecker = require('../passwordChecker/passwordChecker');

/**
 * Clear invalid classes, show help text, clear setCustomValidity.
 * @param {element} formField - Form to be cleared
 * @returns {void}
 */
function clearFieldInvalidState(formField) {
    var $formField = $(formField);
    $formField.removeClass('is-invalid');
    $formField.attr('aria-invalid', false);
    formField.setCustomValidity('');
    $formField.closest('.form-group').removeClass('is-invalid').find('.form-help-text').show();
}

/**
 * Handle event.
 * @param {jQuery.element} $formField - Input element to be updated
 * @returns {void}
 */
function updateFocusFillState($formField) {
    var formFieldIsActive = $formField.is($(document.activeElement));
    if ($formField && $formField.val() !== null) { // Select boxes can return null for value
        if ($formField.val().length > 0 || formFieldIsActive) {
            $formField.closest('.form-group').addClass('focus-fill');
        } else {
            $formField.closest('.form-group').removeClass('focus-fill');
        }
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    var $form = $(this); // this can be not a form element; can be a container of fields.
    var $formFields = $form.find('input, select, textarea');

    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        $form.trigger('invalidForm');
    }

    $formFields.each(function () {
        let $formField = $(this);

        // set or clear error state for each field
        $formField.trigger('invalid', this.validity);

        // add or remove the focus-fill class
        updateFocusFillState($formField);
    });

    // if $form is not a form element this.checkValidity is undefined so valid will be true. Set valid according to if there are invalid fields in the container.
    const $invalidFields = $formFields.filter('.is-invalid');
    valid = valid && !$invalidFields.length;

    if (!valid) {
        let $firstInvalid = $invalidFields.first();

        if ($firstInvalid.prop('tagName') === 'INPUT') {
            $firstInvalid.trigger('select');
        }
        $firstInvalid.trigger('focus');
    }

    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.is-invalid').removeClass('is-invalid was-validated');
}

/**
 * Special handler for numeric fields which don't get validated until onSubmit
 * @returns {void}
 */
function handleNumericOnBlur() {
    var $formControl = $(this);
    var valid = true;
    var rangeError = $formControl.data('range-error');
    var patternError = $formControl.data('pattern-mismatch');
    var val = $formControl.val();
    this.setCustomValidity(''); // clear previous invalid state

    if ($formControl.attr('type') === 'number') {
        val = parseInt(val, 10);
    }

    if (rangeError) {
        var min = parseInt($formControl.attr('min'), 10);
        var max = parseInt($formControl.attr('max'), 10);
        var minLength = $formControl.attr('minlength');
        var maxLength = $formControl.attr('maxlength');
        if (val < min || val > max || val.length < minLength || val.length > maxLength) {
            this.setCustomValidity(rangeError);
            valid = false;
        }
    }

    if (valid && patternError) {
        var regex = new RegExp($formControl.attr('pattern'));
        if (!regex.test(val)) {
            this.setCustomValidity(patternError);
            valid = false;
        }
    }
}

/**
 * Handle on focus in event.
 * @returns {void}
 */
function handleOnFocus() {
    $(this).closest('.form-group').addClass('focus-fill');
}

/**
 * Handle on focus out event.
 * @returns {void}
 */
function handleOnBlur() {
    var $formControl = $(this);
    updateFocusFillState($formControl);

    // skip validation if the field is empty. This prevents "required" errors from popping up if you tab through the form
    if ($formControl.val() === '') {
        clearFieldInvalidState($formControl[0]);
        return;
    }

    var mismatchError = $formControl.data('mismatch-error');
    var $mismatchField = $formControl.data('mismatch-field') && $('#' + $formControl.data('mismatch-field'));

    if ($formControl.attr('type') === 'number' || $formControl.attr('type') === 'tel') {
        handleNumericOnBlur.apply(this);
    } else if (mismatchError && $mismatchField && $mismatchField.length) {
        if ($formControl.val() && $mismatchField.val() !== $formControl.val()) {
            this.setCustomValidity(mismatchError);
            if ($mismatchField.attr('type') === 'password') {
                $mismatchField.trigger('select');
            }
        } else if (this.validationMessage === mismatchError) {
            // if the fields previously did not match, but they do now, remove the custom validity.
            this.setCustomValidity('');
        }
    }

    // Check to see if a password is invalid when focus is lost on the field
    if ($formControl.hasClass('password-field')) {
        var validPassword = passwordChecker.checkPasswordValidity($formControl);
        if (!validPassword) {
            var $invalidPasswordField = $formControl.closest('.form-group').find('.invalid-password');
            $invalidPasswordField.removeClass('d-none');
            this.setCustomValidity('Password does not meet requirements');
        } else {
            this.setCustomValidity('');
        }
    }

    $formControl.trigger('invalid', this.validity);
}

/**
 * Handle on input event.
 * @returns {void}
 */
function handleOnInput() {
    updateFocusFillState($(this));
}

/**
 * Check if the field is visible and valid, or if not visible. For when we only want to validate visible fields,
 * and we don't care if the hidden fields are invalid.
 * @param {element} field The form field of which to check validity.
 * @returns {boolean} whether the field is hidden or valid and visible.
 */
function isVisibleFieldValid(field) {
    return field.validity.valid || !$(field).is(':visible');
}

/**
 * Quietly validate required form elements without triggering invalid ui states.
 * See login.js for an example.
 * @param {element} $form - Form to be validated
 * @returns {boolean} - required elements are filled in
 */
function quietValidation($form) {
    return $form[0].checkValidity();
}

/**
 * Handles proper UI display after autofill.
 * @param {JQuery} $forms - forms to examine for autofill
 */
function empty($forms) {
    try {
        var hasAutoFill = $forms.find(':-webkit-autofill').length;

        if (hasAutoFill) {
            $(':-webkit-autofill').closest('.form-group').addClass('focus-fill');
        }
    } catch (e) {
        // eslint-disable-next-line no-trailing-spaces
    }
}

/**
 * Helper function to validate the current state of submit CTA for registration form
 * @param {HTMLElement} thisField the field in the registration forms that was changed
 */
function setSubmitButtonState(thisField) {
    var $form = $(thisField).closest('form');
    var isValid = quietValidation($form);
    var $button = $form.find('button[type=submit]');

    if (isValid) {
        $button.attr('disabled', false);
        $button.removeClass('disabled');
    } else {
        $button.attr('disabled', true);
    }
}

/**
 * Toggles submit button state if valid.
 * @param {JQuery} $validSubmitForms - forms to examine for submit
 */
function toggleSubmitButtonIfValid($validSubmitForms) {
    // check if page has form which valid submit is active
    if (!$validSubmitForms.length) {
        return;
    }

    /**
     * toggle submit button when all the fields checked  vaidate correctly
     * @param {Object} form the form that is going to be validated
     * @param {Object} $submit is the name of the button that is enabled/disabled
     */
    var toggleSubmit = function (form, $submit) {
        var isValid = form.checkValidity();
        $submit.toggleClass('disabled', !isValid).prop('disabled', !isValid);
    };

    // execute for each form with js-form-valid-submit class
    $validSubmitForms.each(function () {
        var $form = $(this);
        var $submit = $form.find('button[type=submit], input[type=submit]');

        // check form validity for each input/select/textarea keyup blur and change events
        $form.find('input, select, textarea').filter('[required]').on('keyup blur change', function () {
            toggleSubmit($form[0], $submit);
        });

        // initial toggle
        toggleSubmit(this, $submit);
    });
}

module.exports = {
    validateForm: function () {
        $.fn.validateForm = function (event) {
            return validateForm.call(this[0], event || null);
        };
    },
    invalid: function () {
        $('body').on('invalid', 'input, select, textarea', function (e) {
            e.preventDefault();
            var $formControl = $(this);

            if (this.tagName.toLowerCase() === 'textarea' && $formControl.attr('pattern') && $formControl.data('pattern-mismatch')) {
                // textareas do not support the pattern attribute.
                var regEx = new RegExp($formControl.attr('pattern'));
                var patternError = $formControl.data('pattern-mismatch');

                if (!regEx.test($formControl.val())) {
                    this.setCustomValidity(patternError);
                } else if (this.validationMessage === patternError) {
                    // it passed the test and the custom validation message is the same.
                    this.setCustomValidity('');
                }
            }

            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $formControl.addClass('is-invalid');
                $formControl.attr('aria-invalid', true);

                if (this.validity.valueMissing && $formControl.data('missing-error')) {
                    validationMessage = $formControl.data('missing-error');
                } else if (this.value.length < parseInt(this.getAttribute('minlength'), 10) // for IE: doesn't support validity.tooShort
                    && $formControl.data('range-error')) {
                    validationMessage = $formControl.data('range-error');
                } else if ((this.validity.tooLong || this.validity.tooShort) // works with minlength and maxlength attribtues (except for IE)
                    && $formControl.data('range-error')) {
                    validationMessage = $formControl.data('range-error');
                } else if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) // works with min and max attributes
                    && $formControl.data('range-error')) {
                    validationMessage = $formControl.data('range-error');
                } else if (this.validity.patternMismatch && $formControl.data('pattern-mismatch')) {
                    validationMessage = $formControl.data('pattern-mismatch');
                }

                if ($formControl.siblings('.btn-icon').length ||
                    $formControl.is('select') ||
                    $formControl.closest('.form-group').hasClass('form-group--icon-append')) {
                    $formControl.closest('.form-group').addClass('icon-buffer');
                }

                // Set invalid state
                $formControl.closest('.form-group').find('.invalid-feedback').text(validationMessage);
                $formControl.closest('.form-group').addClass('is-invalid');
                $formControl.closest('.form-group').find('.form-help-text').hide();
            } else {
                clearFieldInvalidState(this);
            }
        });
    },

    valid: function () {

    },

    empty: function () {
        $(window).on('load', function () {
            var formsSelector = 'form';
            var $forms = $(formsSelector);
            empty($forms);

            $('body').on('form:created', formsSelector, function () {
                empty($(this));
            });
        });
    },

    submit: function () {
        $('body').on('submit', 'form', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('body').on('click', 'form button[type="submit"], form input[type="submit"]', function (e) {
            var isFullyCoveredByAny = $('.billing-payment-options-block').data('fully-covered');
            var paymentMethod = $('#selectedPaymentMethod').val() || $('#selectedPaymentOption').val();

            if (!(isFullyCoveredByAny === true || paymentMethod === 'PayByLink')) {
                // clear all errors when trying to submit the form
                var theForm = this.form;
                clearForm(theForm);
                validateForm.call(theForm, e);
            }
        });
    },

    /**
     * Support for Material style form inputs
     */
    inlineLabels: function () {
        var textInputFields = 'input, select, textarea';

        $('body')
            .on('focus', textInputFields, function (event) {
                handleOnFocus.apply(this, [event]);
            })
            .on('blur', textInputFields, function (event) {
                handleOnBlur.apply(this, [event]);
            })
            .on('input', textInputFields, function (event) {
                handleOnInput.apply(this, [event]); // Update when autofill happens
            });

        // Event for adding or removing the "focus-fill" class, which makes the label move.
        $(document).on('validation.updateFocusFillState', function () {
            $(textInputFields).each(function () {
                updateFocusFillState($(this));
            });
        });

        // Set initial states
        $(document).trigger('validation.updateFocusFillState');
    },

    /**
     * Setup enable/disable submit button feature to a form with js-form-valid-submit cssclass
     * If the form is invalid then submit button will be disabled
     * If the form is valid then submit button will be enabled
     */
    toggleSubmitButtonIfValid: function () {
        var formsSelector = 'form.js-form-valid-submit';
        var $forms = $(formsSelector);
        toggleSubmitButtonIfValid($forms);

        $('body').on('form:created', formsSelector, function () {
            toggleSubmitButtonIfValid($(this));
        });
    },

    /**
     * Toggle the disabled state on the submit button if any of the form elements are empty.
     * Used on small forms where the fields are not required unless submitting the form.
     * (ie: we don't want to show an error if blurring an empty field, but can't submit unless all fields are filled)
     * Used on:
     *     Apply Promo Code on the cart page
     *     Apply Promo Code on the checkout page
     *     Gift Card on the checkout page
     */
    toggleSubmitOnEmptyForm: function () {
        const noEmptyFormSelector = '.js-no-empty-submit'; // this behavior should only apply to elements with this class.
        /**
         * If any of the form elements are empty, disable the submit button.
         * @param {jQuery} $form The container of the fields that determine the disabled state of the button. (not necessarily a form element)
         */
        const toggleSubmit = ($form) => {
            let isIncomplete = false;
            const btnSelector = $form.data('submit-btn-selector');
            const $submitBtn = btnSelector ? $(btnSelector) : $form.find('button[type=submit]');
            const $formElements = $form.find('input, select, textarea');
            $formElements.each(function () { // eslint-disable-line consistent-return
                // look for empty fields
                if (!$(this).val()) {
                    isIncomplete = true;
                    return false; // No need to keep checking all fields. Break out of .each() loop
                }
            });
            $submitBtn.prop('disabled', isIncomplete);
        };

        // on page load, disable empty forms
        $(noEmptyFormSelector).each(function () {
            const $form = $(this);
            toggleSubmit($form);
        });

        // on input, check if the form is empty
        $(noEmptyFormSelector).find('input, select, textarea').on('input change blur', function () {
            const $form = $(this).closest(noEmptyFormSelector);
            toggleSubmit($form);
        });
    },

    functions: {
        isVisibleFieldValid: isVisibleFieldValid,
        quietValidation: quietValidation, // todo: remove this from login.js and stores.js, replace with form.checkValidity();
        clearFieldInvalidState: clearFieldInvalidState,
        clearForm: clearForm,
        setSubmitButtonState: setSubmitButtonState
    }
};
