'use strict';

const utils = require('../components/utils');
const minicartSaleItemAlertBadge = require('../components/minicartSaleItemAlertBadge');

const isCartPage = $('.page').data('action') === 'Cart-Show';

/**
 * renders an error alert at the top of the page.
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    $('.cart-error').empty().alert({
        content: message,
        theme: 'danger',
        placement: 'inline',
        autoClose: false
    });
}

/**
 * Stolen from product/base.js so we don't need to import and execute the whole file.
 * Retrieves the relevant pid value
 * @param {jQuery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
const getPidFromQuickview = function ($el) {
    if ($('.quickview-modal').hasClass('show') && !$('.product-set').length) {
        return $el.closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        return $el.closest('.product-detail').find('.product-id').data('pid');
    }
    return $('.product-detail:not(".bundle-item")').data('pid');
};

/**
 * Confirmation for deleting an item from the cart
 * @param {Object} $this - jQuery target of event
 */
function deleteItemConfirmation($this) {
    const productID = $this.data('pid');
    const uuid = $this.data('uuid');
    const urlParams = {
        pid: productID,
        uuid: uuid,
        cartSource: isCartPage ? 'cart' : 'mini-cart' // For dtm-events
    };
    const url = utils.setUrlData($this.data('action'), urlParams);

    $('body > .modal-backdrop').remove();
    $.spinner().start();
    $('body').trigger('cart:beforeUpdate');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json'
    }).done(data => {
        window.dispatchEvent(new Event('cart:updated:dtm'));
        if (data.basket.items.length === 0 && isCartPage) {
            window.location.reload();
        } else {
            $.spinner().stop();

            if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length) {
                data.toBeDeletedUUIDs.forEach(uuidToDelete => {
                    $(`uuid-${uuidToDelete}`).remove();
                });
            }
            $(`.uuid-${uuid}`).remove();
            if (!data.basket.hasBonusProduct) {
                $('.bonus-product').remove();
            }
        }

        // if we're on the cart page, cart.js will handle this event
        $('body').trigger('cart:update', data.basket);

        $('body').trigger('minicart:updatecount', data.basket.numItems);

        // TODO: data.reservation is always an object (may be empty).
        //       if empty or reservableProductsInCart is falsy,
        //       reservationTimerHelper.getDestroyTimer() returns a function that is not executed.
        if (data.reservation) {
            const reservationTimerHelper = require('../components/reservationTimer');
            if (data.reservation.reservableProductsInCart) {
                reservationTimerHelper.updateReservationData(data);
            } else {
                reservationTimerHelper.getDestroyTimer();
                delete window.resources.reservation;
            }
        }

        if (data.dtmEvent) {
            $('body').trigger('cart:emitEvents', { events: data.dtmEvent });
        }

        if (data && 'dtmLayer' in data && 'digitalData' in window) {
            if (data.dtmLayer.pageInstanceID) {
                window.digitalData.pageInstanceID = data.dtmLayer.pageInstanceID;
            }
            if (data.dtmLayer.page) {
                window.digitalData.page = data.dtmLayer.page;
            }
            if (data.dtmLayer.user) {
                window.digitalData.user = data.dtmLayer.user;
            }
            if (data.dtmLayer.cart) {
                window.digitalData.cart = data.dtmLayer.cart;
                minicartSaleItemAlertBadge.toggleAlertBadge();

                // Update Stylitics widget on cart page if it exists
                $('body').trigger('stylitics:update');
            }
        }
    }).fail(err => {
        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
        } else {
            $.spinner().stop();
            createErrorNotification(err.responseJSON.errorMessage);
        }
    });
}

/**
 * Creates a confirmation modal when the Remove button is clicked, on cart or minicart.
 * @param {JQuery} $this the remove button that was clicked. it contains data attributes for creating the modal.
 */
function removeProductModal($this) {
    const productName = $this.data('name');
    const pid = $this.data('pid');
    const uuid = $this.data('uuid');
    const isBonusProduct = $this.data('bonus-product');
    const showSFL = $this.data('show-sfl-btn') && !$this.data('sfl-requires-login');

    const removeButton = `<button type="button" class="btn btn-primary btn-narrow cart-delete-confirmation-btn"
        data-pid="${pid}"
        data-action="${$this.data('action')}"
        data-uuid="${uuid}"
        data-dismiss="modal">${window.resources.cart.removeButton}</button>`;

    const cancelButton = `<button type="button" class="btn btn-secondary btn-narrow" data-dismiss="modal">${window.resources.cart.cancelButton}</button>`;

    const sflButton = `<button type="button" class="btn btn-secondary btn-narrow js-saveForLater-product"
        data-pid="${pid}"
        data-uuid="${uuid}"
        data-remove-from-cart="true"
        data-dismiss="modal">${window.resources.cart.saveForLater}</button>`;

    const buttons = [removeButton];
    buttons.push(showSFL ? sflButton : cancelButton);

    const modalContent = isBonusProduct ? `<p>${window.resources.cart.removeBonusProductBody}</p><p class="product-to-remove mb-0">${productName}</p>` : `<p>${window.resources.cart.removeProductBody}</p><p class="product-to-remove mb-0">${productName}</p>`;
    const $confirmModal = $.modal({
        title: window.resources.cart.removeProduct,
        content: modalContent,
        modalSizeClass: 'modal-md',
        buttons
    });

    $confirmModal.on('shown.bs.modal', function () {
        $confirmModal.on('click', '.cart-delete-confirmation-btn', function (e) {
            e.preventDefault();
            deleteItemConfirmation($(this));
        });
    });

    $confirmModal.one('hidden.bs.modal', function () {
        // if the modal was closed without removing the product, make sure the qty is not 0
        const $qtyField = $this.closest('.product-card').find('.js-quantity'); // Cart-page only
        if ($qtyField.val() < 1) {
            $qtyField.val(parseInt($qtyField.data('pre-select-qty'), 10));
        }

        // We remove here so that the deletion happens before the modal closes
        $confirmModal.remove();
        $.enableScroll();
    });
}

/**
 * Click handler for when user clicks the save for later button on a product
 * @param {Object} $this - jQuery target of event
 */
function saveForLater($this) {
    let pid = $this.data('pid');
    let uuid = $this.data('uuid');
    let removeFromCart = $this.data('removeFromCart');
    let formData = {
        pid,
        uuid,
        removeFromCart
    };

    $.spinner().start();
    $('body').trigger('cart:beforeUpdate');
    $.ajax({
        url: window.resources.cart.saveForLaterUrl, // Cart-SaveForLaterProductLineItem
        type: 'POST',
        data: formData,
        dataType: 'json'
    }).done(function (data) {
        if (data.error) {
            $.spinner().stop();
            createErrorNotification(data.errorMessage);
            return;
        }

        if (isCartPage) {
            $('body').trigger('cart:refresh');

            $('.js-saveforlater-listsize').html(data.listSizeMsg).data('listsize', data.lineItemsTotalCount);

            // update stuff
            if (!data.cart.numItems) {
                location.reload();
            } else {
                $('body').trigger('cart:update', data.cart);
            }
        } else { // saved from minicart
            $.spinner().stop();
            $(document).trigger('minicart:updatecount', data.cart.numItems);
        }

        $.alert({
            content: data.alertMsg,
            theme: 'success',
            placement: 'banner'
        });


        if (data && 'dtmLayer' in data && 'digitalData' in window) {
            if (data.dtmLayer.pageInstanceID) {
                window.digitalData.pageInstanceID = data.dtmLayer.pageInstanceID;
            }
            if (data.dtmLayer.page) {
                window.digitalData.page = data.dtmLayer.page;
            }
            if (data.dtmLayer.user) {
                window.digitalData.user = data.dtmLayer.user;
            }
            if (data.dtmLayer.cart) {
                window.digitalData.cart = data.dtmLayer.cart;
                minicartSaleItemAlertBadge.toggleAlertBadge();
            }
        }

        if (data.dtmEvent) {
            $('body').trigger('cart:emitEvents', { events: data.dtmEvent });
        }

        // Update Stylitics widget on cart page if it exists
        $('body').trigger('stylitics:update');
    }).fail(function (err) {
        if (err.responseJSON.redirectUrl) {
            location.href = err.responseJSON.redirectUrl;
        } else {
            createErrorNotification(err.responseJSON.errorMessage);
            $.spinner().stop();
        }
    });
}

/**
 * Sets up Remove Coupon modal with the data needed to remove the coupon.
 * `.remove-coupon` button triggers a modal via `data-toggle="modal"`.
 * Modal markup is already hidden on page with id `#removeCouponModal`
 * @param {jQuery} $this - the clicked remove button
 */
function removeCoupon($this) {
    let couponCode = $this.data('code');

    $('.delete-coupon-confirmation-btn').data({
        uuid: $this.data('uuid'),
        code: couponCode
    });
    $('.coupon-to-remove').text(couponCode);
}

/**
 * Updates the CGR rewards card and banner on cart/checkout pages
 * @param {Object} data - AJAX response from the server
 */
function updateRewardsCardAndBanner(data) {
    if (data.rewardsCard) {
        // Check for points multiplier
        $('.js-rewards-banner-promomsg').toggleClass('d-none', data.rewardsCard.basketMultiplier <= 1);

        // update rewards balance messaging, if applicable
        if (data.rewardsCard.loyaltyActive) {
            if (data.rewardsCard.appliedRewards && data.rewardsCard.appliedRewards.value > 0) { // rewards applied
                $('.js-rewards-balance-msg').html('Rewards applied: <span class="checkoutRewardsBanner__detail-highlight"><strong>' + data.rewardsCard.appliedRewards.formatted + '</strong></span>');
                $('.js-rewards-banner-remaining').text(data.rewardsCard.remainingRewards);
                $('.checkoutRewardsBanner__detail-small').toggleClass('d-none', false);
            } else { // rewards removed
                if (data.rewardsCard.rewardsBalance) {
                    if (data.rewardsCard.rewardsBalance.available.numericValue < 5) {
                        $('.js-rewards-balance-msg').html('Rewards Balance: <span class="checkoutRewardsBanner__detail-highlight"><strong>' + data.rewardsCard.rewardsBalance.available.currencyFormattedString + '</strong></span>');
                    } else {
                        $('.js-rewards-balance-msg').html('Available Rewards: <span class="checkoutRewardsBanner__detail-highlight"><strong>' + data.rewardsCard.rewardsBalance.available.currencyFormattedString + '</strong></span>');
                    }
                }

                $('.checkoutRewardsBanner__detail-small').toggleClass('d-none', true);
            }
        }

        // update earned rewards on basket
        // Toggle Cart and Checkout Banner Guest Rewards Message
        const cartWillEarnRewards = data.rewardsCard.rewards && data.rewardsCard.rewards.promoRewards.value > 0;
        $('.js-cart-rewards-banner, .js-checkout-shipping-rewards-banner').toggleClass('d-none', !cartWillEarnRewards);
        $('.js-cart-rewards-banner-zero, .js-checkout-shipping-rewards-banner-zero').toggleClass('d-none', cartWillEarnRewards);

        // Toggle Rewards Card Basket Earnings Message
        $('.js-rewards-earnings-msg').toggleClass('d-none', !cartWillEarnRewards);
        $('.js-rewards-earnings-msg-zero').toggleClass('d-none', cartWillEarnRewards);

        if (cartWillEarnRewards) {
            if (data.rewardsCard.rewards.strikeThrough) {
                $('.js-rewards-banner-strikethrough').toggleClass('d-none', false);
                $('.js-rewards-banner-strikethrough').html(data.rewardsCard.rewards.strikeThrough);
                $('.js-rewards-banner-earnamt').text(data.rewardsCard.rewards.promoRewards.formatted);
            } else {
                $('.js-rewards-banner-strikethrough').toggleClass('d-none', true);
                $('.js-rewards-banner-earnamt').text(data.rewardsCard.rewards.baseRewards.formatted);
            }
        }
    }
}

/**
 * confirmation of coupon removal from total
 * @param {jQuery} $this - the clicked confirmation button ( $('.delete-coupon-confirmation-btn') )
 */
function removeCouponConfirmation($this) {
    const url = utils.setUrlData($this.data('action'), { // Cart-RemoveCouponLineItem
        code: $this.data('code'),
        uuid: $this.data('uuid')
    });

    $('body > .modal-backdrop').remove();

    $.spinner().start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json'
    }).done(data => {
        $('body').trigger('cart:update', data);

        $('.js_braintree_paypal_cart_button').removeClass('disabled');
        $.spinner().stop();

        if ('dtmLayer' in data && data.dtmLayer && 'digitalData' in window) {
            ['pageInstanceID', 'page', 'user', 'cart'].forEach(prop => {
                if (data.dtmLayer[prop]) {
                    window.digitalData[prop] = data.dtmLayer[prop];
                }
            });
        }

        $('body').trigger('updateBillingBlockDataAttributes', data);

        if (data.dtmEvent) {
            $('body').trigger('cart:emitEvents', { events: data.dtmEvent });
        }

        updateRewardsCardAndBanner(data);
    }).fail(err => {
        if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
        } else {
            createErrorNotification(err.responseJSON.errorMessage);
            $.spinner().stop();
        }
    });
}

/**
 * Common handler for emitting analytics events
 * @param {Array} events - an array of dtmEvent objects
 */
function emitCartEvents(events) {
    if ('_satellite' in window && !!events && events.length) {
        events.forEach((action) => {
            _satellite.track(action.event, action.data); // eslint-disable-line no-undef
        });
    }
}

module.exports = {
    init: function () {
        $('body')
            .on('click', '.js-remove-product', function (e) {
                e.preventDefault();
                removeProductModal($(this));
            })
            .on('click', '.js-saveForLater-product', function (e) {
                e.preventDefault();
                saveForLater($(this));
            })
            .on('click', '.remove-coupon', function (e) {
                e.preventDefault();
                removeCoupon($(this));
            })
            .on('click', '.delete-coupon-confirmation-btn', function (e) {
                e.preventDefault();
                removeCouponConfirmation($(this));
            })
            .on('cart:emitEvents', function (e, data) {
                e.preventDefault();
                emitCartEvents(data.events);
            });
    },
    updateRewardsCardAndBanner: updateRewardsCardAndBanner,
    getPidFromQuickview: getPidFromQuickview
};
