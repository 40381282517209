'use strict';

const utils = require('../components/utils');

/**
 * Sets or clears the redirect data attributes on the Log In and Sign Up forms.
 * @param {string} redirectUrl The url to be redirected to after loging in or signing up.
 * @param {JQuery} $wizardContainer the wizard container
 */
function setFormRedirectData(redirectUrl, $wizardContainer) {
    $wizardContainer.find('form.login').data('redirect', redirectUrl);
    $wizardContainer.find('form.registration').data('redirect', redirectUrl);
}

/**
 * Initialize the Authorization Gateway Wizard
 * @param {JQuery} $wizardContainer container for the wizard
 * @param {string} redirectUrl The url to be redirected to after loging in or signing up.
 * @param {boolean} reauthenticate boolean flag for reauthentication experience
 * @param {JQuery} $accountDrawer the account drawer that contains the wizard
 */
function initNewWizard($wizardContainer, redirectUrl, reauthenticate, $accountDrawer) {
    $wizardContainer.wizard({
        init: function ($wiz) {
            const $authGatewayForm = $wiz.find('form.authGateway');
            $authGatewayForm.trigger('form:created');

            // Register Auth Gateway form's submission button
            $wiz.on('submit', 'form.authGateway', function (e) {
                e.preventDefault();
                $accountDrawer.spinner().start();

                let isSuccess;

                $.ajax({
                    url: $authGatewayForm.attr('action'),
                    type: 'post',
                    data: $authGatewayForm.serialize(),
                    success: function (response) {
                        // Handle bad input
                        let $invalidField = $authGatewayForm.find('.invalid-feedback');
                        if (response.error) {
                            $invalidField.text(response.msg).show();
                            return;
                        }
                        $invalidField.text('').hide();

                        $authGatewayForm.trigger('form:preauth:success');

                        const $signInOrCreateAccountStep = $wiz.find('[data-step-id=signInOrCreateAccount]');
                        $signInOrCreateAccountStep.empty();
                        $signInOrCreateAccountStep.append($(response));

                        // Identify the form shown & set up respective listeners
                        let isLoginFlow = $wizardContainer.find('.account-drawer__login').length;

                        if (isLoginFlow) {
                            $wizardContainer.find('form.login').trigger('form:created');
                        } else {
                            $wizardContainer.find('form.registration').trigger('form:created');

                            $wizardContainer.on('click', '.js-btn-registration', function () {
                                $wizardContainer.find('.js-rewards-terms').prop('checked', !$(this).hasClass('js-btn-registration-no-rewards'));
                            });
                        }

                        // Set form redirect data regardless of form provided
                        if (redirectUrl) {
                            setFormRedirectData(redirectUrl, $wizardContainer);
                        }

                        isSuccess = true;
                    },
                    complete: function () {
                        $accountDrawer.spinner().stop();

                        if (isSuccess) {
                            /**
                             * formsDataLayer.js depends upon the 'ajaxSuccess' event.
                             * As such, we should not move to the next step until the ajax call is 'complete'
                             * to avoid timing issues. Especially with form field focus, which updates the data layer.
                             */
                            $wiz.nextStep();
                        }
                    },
                    error: function () {
                        $accountDrawer.spinner().stop();
                        var url = window.location.pathname;
                        url = utils.appendParamToURL(url, 'signin', 'true');
                        url = utils.appendParamToURL(url, 'autherror', 'true');
                        window.location.href = url;
                    }
                });
            });

            $wiz.on('click', '.js-auth-wizard-back', function () {
                $wiz.prevStep();
            });

            // Store Email id of customer in session when using forgot password
            $wizardContainer.on('click', '.account-content .forgot-password a, #password-reset', function (e) {
                e.preventDefault();
                const $currentElement = $(e.currentTarget);
                const emailId = $currentElement.closest('form').find('input[name=loginEmail]').val();

                if (emailId && emailId.length && !window.IsKiosk) {
                    sessionStorage.setItem('userEmailId', emailId);
                }

                window.location = $currentElement.attr('href');
            });
        },
        afterStepEnter: function (stepId, $wiz) {
            // After steping into the second step, focus on the first non-email input
            if (stepId === 'signInOrCreateAccount') {
                const $signInOrCreateAccountStep = $wiz.find('[data-step-id=signInOrCreateAccount]');
                // For Login, find the password field. For Registration, find first name
                const selector = $wizardContainer.find('.account-drawer__login').length ? 'input[type=password]' : 'input#firstName';

                $signInOrCreateAccountStep.find(selector).focus();
            }
        }
    });
}

class AuthWizard {
    constructor($wizardContainer, redirect, reauthenticate, $accountDrawer) {
        this.$accountDrawer = $accountDrawer;
        initNewWizard($wizardContainer, redirect, reauthenticate, $accountDrawer);
    }

    setFormRedirectData(redirect) {
        setFormRedirectData(redirect, this.$accountDrawer);
    }
}

module.exports = AuthWizard;
