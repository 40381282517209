/**
 * SMS Consent Form Submission Handler
 *
 * This module handles the form submission process for the SMS consent form.
 * It is validates the form, and sends the form data to the server using an AJAX POST request.
 */

'use strict';

var clientSideValidation = require('./clientSideValidation');

module.exports = {
    init: function () {
        $(document).on('submit', '.js-smsConsentForm', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var $drawer = $form.closest('.drawer');

            if (!$form.validateForm()) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        if (data.nextStepMarkup) {
                            $form.closest('.drawer__body').html(data.nextStepMarkup);

                            $drawer.on('hide.drawer', function () {
                                window.location.reload();
                            });
                        } else {
                            $drawer.find('.close').click();
                        }
                        $.spinner().stop();
                    } else {
                        $drawer.find('.close').click();

                        $.modal({
                            title: data.title,
                            content: `<p class='py-2 m-0'>${data.msg}</p>`,
                            modalSizeClass: 'modal-md',
                            centeredClass: 'modal-dialog-centered',
                            buttons: [
                                '<button class="btn btn-primary" data-dismiss="modal">Ok</button>'
                            ]
                        }).one('shown.bs.modal', function () {
                            $(this).find('.modal-body').addClass('py-0');
                        });

                        $.spinner().stop();
                    }
                },
                error: function () {
                    // Show the invalid state of the input field
                    var $inputWraper = $form.find('.form-group');
                    $inputWraper.addClass('is-invalid');
                    $form.find('.form-control').addClass('is-invalid');
                    $inputWraper.find('.invalid-feedback').text('An error occurred.');
                    $inputWraper.find('.form-help-text').hide();

                    $.spinner().stop();
                }
            });
        });

        $(document).on('click', '.js-smsDrawerOpen', function () {
            var $this = $(this);
            var phoneNumber = $this.data('phonenumber');
            var drawerSettings = $this.data('settings');

            if (drawerSettings && drawerSettings.src) {
                $.drawer(drawerSettings).on('contentAsyncLoaded.drawer', function () {
                    var $phoneInput = $(this).find('#smsConsentPhoneField');

                    if ($phoneInput.length) {
                        var cleave = require('../components/cleave');

                        cleave.formatPhoneField($phoneInput[0]);

                        if (phoneNumber) {
                            $phoneInput.val(phoneNumber).parent().addClass('focus-fill');
                        }
                    }
                });
            }
        });

        $(document).on('change', '.js-sms-consent-field', function () {
            var $requiredField = $('.js-optin-requred-field');

            if ($requiredField.length) {
                $requiredField
                    .prop('required', this.checked)
                    .attr('aria-required', this.checked);
                $requiredField
                    .closest('.form-group')
                    .toggleClass('required', this.checked);

                clientSideValidation.functions.setSubmitButtonState(this);
            }
        });
    }
};
