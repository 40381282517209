'use strict';
/**
 * Global modules.
 */
const Swiper = global.Swiper = require('swiper/swiper-bundle.min.js');
const debounce = require('lodash/debounce');
/**
 * Global constants.
 */
const swipers = [];

/**
 * Adjusts the number of visible slides and slides per group based on the container's width.
 * @param {Object} sliderEl - The Swiper instance or slider object.
 */
function adjustSlides(sliderEl) {
    if (!sliderEl || !sliderEl.el) {
        return;
    }

    let slidesPerView = Math.floor(sliderEl.el.clientWidth / sliderEl.swatchWidth);

    if (sliderEl.slides.length <= slidesPerView) {
        sliderEl.el.swiper.destroy(true, true);
        return;
    }

    slidesPerView = Math.floor(sliderEl.width / sliderEl.swatchWidth);
    // eslint-disable-next-line no-param-reassign
    sliderEl.params.slidesPerView = slidesPerView;
    // eslint-disable-next-line no-param-reassign
    sliderEl.params.slidesPerGroup = slidesPerView;
    sliderEl.update();
}

/**
 * Initializes and restores swiper for swatch gallery
 * @param {JQuery} $container - modal swiper container
 */
function initColorSwatchSwiper($container = $(document)) {
    const $selector = $container.find('.swiper-container--swatches:not(.swiper-initialized)');
    $selector.each(function (index) {
        const $swiperEl = $(this);
        const $colorSwatches = $swiperEl.find('.js-color-swatch');
        const containerWidth = $swiperEl.width();
        const swatchWidth = $colorSwatches.first().outerWidth(true);
        const isSwatchesFitContainer = containerWidth >= $colorSwatches.length * swatchWidth;

        if (!$swiperEl.swiper && !isSwatchesFitContainer) {
            const swiperData = {
                loop: false,
                slidesPerView: 'auto',
                slidesPerGroupAuto: true,
                watchOverflow: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    resize: debounce(adjustSlides, 300),
                    afterInit: function () {
                        this.swatchWidth = swatchWidth;
                        adjustSlides(this);
                    }
                }
            };

            swipers[index] = new Swiper(this, swiperData);
        }
    });
}

/**
 * Initializes and restores swiper for image gallery
 * @param {JQuery} $container - modal swiper container
 */
function initTileImageSwiper($container = $(document)) {
    const $selector = $container.find('.image-carousel-enabled .selected .swiper-container--images:not(.swiper-initialized)');
    $selector.each(function (index) {
        const $swiperEl = $(this);

        if (!$swiperEl.swiper) {
            const swiperData = {
                loop: true,
                slidesPerView: 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            };

            swipers[index] = new Swiper(this, swiperData);
        }
    });
}

module.exports = {
    init: function ($selector = $('.swiper-container[data-swiper]')) {
        var swiper = [];
        $selector.each(function (index) {
            // do not duplicate existing swiper instances
            if (!this.swiper) {
                var $el = $(this);
                var swOptions = $el.data('swiper');

                swOptions.on = {
                    init: function () {
                        $el.addClass('swiper-loaded');
                    }
                };

                $el.on('updateLazyImages', () => {
                    if ($el[0].swiper) {
                        var $images = $('.swiper-wrapper', $el).find('img');
                        $images.each((i) => {
                            if ($images[i].hasAttribute('src')) {
                                $images.eq(i).attr('src', $images.eq(i).attr('data-src'));
                            }
                        });
                    }
                });

                swiper[index] = new Swiper(this, swOptions);
            }
        });
    },
    destroy: function ($selector) {
        if (!$selector) { return; }
        $selector.each(function () {
            if ($(this)[0].swiper) {
                $(this)[0].swiper.destroy(true, true);
            }
        });
    },
    initColorSwatchSwiper: initColorSwatchSwiper,
    initTileImageSwiper: initTileImageSwiper,
    handleEvents: function () {
        $(window)
            .on('resize', debounce(() => initColorSwatchSwiper(), 300));
        $(document)
            .on('search:lazyLoadSuccess', () => {
                initColorSwatchSwiper();
                initTileImageSwiper();
            });
    }
};
